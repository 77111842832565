import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

// frontend/javascript/index.js

import "bridgetown-quick-search";

console.info("Bridgetown is loaded!");
